import React from "react";
import './Header.scss';
import './HeaderMenuLinks.scss';

const Header = () => {
    return (
        <header className="header-container">
            <nav className="d-flex justify-content-between align-items-center">
                {/* se cambio la clase d-lg-none a d-lg-block*/}
                <ul style={{ background: '#00bfb3' }}>
                    <li>
                        <a id="show-sidebar" className="menu-link menu-link-slide">
                            <span><em></em></span>
                        </a>
                    </li>
                </ul>
                <img
                    style={{
                        width: 'auto',
                        height: '60px',
                    }} 
                    src="./img/logo-mutual-2021.png"
                    className="rounded mx-auto" 
                    alt="Logo" 
                />
                <span></span>
            </nav>
        </header>
    );
}

export default Header;
