/*!
 *
 * Centric - Bootstrap Admin Template
 *
 * Version: 2.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import {unregister } from './interceptor';

// Vendor dependencies
import './Vendor';

// App Routes
import Routes from './Routes';
import './components/Ripple/Ripple.init.js';

const App = () => {
  // const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
  const basename = process.env.NODE_ENV === 'development' ? '/' : '/';    

  return (
      <BrowserRouter basename={basename}>
          <Routes />
      </BrowserRouter>
  );
}

export default App;
