import axios from 'axios';
import Swal from 'sweetalert2';
// import { Redirect } from 'react-router-dom';

const token = localStorage.getItem("userToken");
// const expiracion = localStorage.getItem("expiracion");


axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log('config interceptors request:' ,config);

    config.headers =   {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': 'Bearer ' + token 
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('config interceptors response:' , response);    
    
    return response;
  }, function (error) {

    // console.log('Interceptor Errors');
    // console.log('error', error);
    // console.log('error.response', error.response);
    // console.log('error.response.data)', error.response.data);
    // console.log('error.response.data.errors ', error.response.data.errors);

    // if(error.response.data.errors != undefined){
    //   console.log('no undefined');
    // }else{
    //   console.log('undefined');
    // }


    // if(error.response.data.errors.expiredAt != undefined){
    //   console.log('undefined expiredAt');
    // }else{
    //   console.log('no undefined expiredAt');
    // }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error


    // if (error.response.data.errors.expiredAt === 'undefined') { // lanza un ReferenceError
    //   console.log('undefined');
    // }else{
    //   console.log('OK');
    // }
  

    if(error.response.data.errors != undefined){
        if(error.response.data.errors.expiredAt != undefined)
        {        
            var valHtml = document.createElement("p");
            valHtml.setAttribute('class', 'col-xs-12');  
            valHtml.setAttribute('style', 'font-size:16px'); 
            localStorage.clear();        
            var texto = document.createTextNode('Su sesión ha expirado');
            valHtml.appendChild(texto);
            Swal.fire({
            title: 'Advertencia',
            icon: 'warning',
            html: valHtml,                                   
        }).then((result) => {
            
            window.location.href = '/login';
            // if (result.value) {
            //      window.location.href = '/login';
            //     return false;
            // }
        })
      }
                          
    //   Swal.fire({
    //     title: 'Advertencia',
    //     icon: 'success',
    //     html: valDivHtml,                           
    //     showCancelButton: false,
    //     // customClass: 'sweetalert-lg',
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Ok'
    //   }).then((result) => {
    //     if (result.value) {
    //         estadoValidacionFile= false;
    //         return false;
    //     }
    //   })


        // window.location.href = '/login';
    }

    
    return Promise.reject(error);
  });