const Menu = [
    {
        name: 'Evolutivo',
        path: '/evolutivo',                
        icon: 'img/icons/connection-bars.svg',                
        label: { value: 1, classNme: 'bg-success' }
    },
    {
        name: 'Comparativo',
        path: '/comparativo',                
        icon: 'img/icons/levels.svg',                
        label: { value: 1, classNme: 'bg-success' }
    },
    {
        name: 'Metodologia',
        path: '/metodologia',                
        icon: 'img/icons/book.svg',                
        label: { value: 1, classNme: 'bg-success' }
    },
];

export default Menu;