import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';
/* eslint-disable-next-line */
import Core from './components/Core/Core';
/* eslint-disable-next-line */
import Bootstrap from './components/Bootstrap/Bootstrap';
/* eslint-disable-next-line */
import Common from './components/Common/Common';
/* eslint-disable-next-line */
import Colors from './components/Colors/Colors';
/* eslint-disable-next-line */
import FloatButton from './components/FloatButton/FloatButton';
/* eslint-disable-next-line */
import Utils from './components/Utils/Utils';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;
const DashboardTab = lazy(() => import('./views/Dashboard/indexDashboardTab'));
const AgrupacionTab = lazy(() => import('./views/Dashboard/indexAgrupacionTab'));
const HomeScreen = lazy(() => import('./views/Home/HomeScreen'));

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn'
 
    return (            
        <Core>
            <TransitionGroup>
            <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                <div>
                    <Suspense fallback={<PageLoader/>}>
                        <Switch location={location}>
                            {/* dashboard */}
                            <Route exact path="/evolutivo" component={waitFor(DashboardTab)}/>
                            <Route exact path="/comparativo" component={waitFor(AgrupacionTab)}/>
                            {/* METODOLOGÏA */}
                            <Route exact path="/metodologia" component={waitFor(HomeScreen)}/>
                            <Redirect to="/evolutivo"/>
                        </Switch>
                    </Suspense>
                </div>
            </CSSTransition>
            </TransitionGroup>
        </Core>
    )
}

export default withRouter(Routes);
